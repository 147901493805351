.CrossButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e6e6e6;
    color: #787878;
    border-radius: 0 2rem 0 1rem;
    font-weight: 200;
    font-size: 2.75rem;
    border: 0;
    outline: none;
    padding: .6rem 1.2rem;
    line-height: 1;
    display: block;
    cursor: pointer;
    transition: background-color .25s ease;
}
.CrossButton:hover {
    background-color: #b41c18;
    color: #fff;
}

@media (max-width: 500px) {
    .CrossButton {
      font-size: 2rem;
      padding: .4rem .8rem;
    }
  }