.LastStepBlock {
    background: linear-gradient(180deg, #000000, #262626 80%, #202020);
    color: #ffffff;
    min-height: 75vh;
    position: relative;
    overflow: hidden;
}
.LastStepBlock::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: -5%;
    left: 62%;
    width: 50%;
    height: 120%;
}
.LastStepBlock > * {
    position: relative;
    z-index: 10;
}
.LastStepImageWrapper {
    position: relative;
    z-index: 100;
    max-width: 70vw;
    margin-left: auto;
    margin-right: auto;
}
.LastStepImageWrapper::before {
    content: "";
    position: absolute;
    top: calc(1.5% - 6.5%);
    left: -4.5%;
    width: calc(100% + 10%);
    height: calc(98.5% + 9%);
    border-radius: 100rem;
    border: 4px dotted #8d8d8d;
    z-index: -1;
    animation: rotate 100s reverse linear infinite;
    opacity: .6;
}
.LastStepImageWrapper::after {
    content: "";
    position: absolute;
    top: calc(-0.5% - 10%);
    left: -10%;
    width: calc(97% + 23%);
    height: calc(98.5% + 20%);
    border-radius: 100rem;
    border: 4px dotted #8d8d8d;
    z-index: -1;
    animation: rotate 120s normal linear infinite;
    opacity: .25;
}

@keyframes rotate {
    0% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    25% {
        transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
        transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
        transform: rotate3d(0, 0, 1, 270deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, 360deg);
    }
}